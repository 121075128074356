// src/mixpanel.js
import mixpanel from 'mixpanel-browser';
const projectToken = "313a452f33e0b3796e86020974627de9";

mixpanel.init(projectToken, {debug: true});

const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};
const ENV_PREFIX = process.env.REACT_APP_ENV_PREFIX || 'production';

const localStorageSetItem = (key, value) => {
  localStorage.setItem
(`${ENV_PREFIX}_${key}`, value);
};

const localStorageGetItem = (key) => {
  return localStorage.getItem
(`${ENV_PREFIX}_${key}`);
};

const localStorageRemoveItem = (key) => {
  localStorage.removeItem(`${ENV_PREFIX}_${key}`);
};



let uniqueID = localStorageGetItem('unique_id');
if (!uniqueID) {
  uniqueID = generateUUID();
  localStorageSetItem('unique_id', uniqueID);
}


mixpanel.identify(uniqueID);

// Function to normalize the referrer
const normalizeReferrer = (referrer) => {
  if (referrer.includes('instagram')) {
    return 'Instagram';
  } else if (referrer.includes('facebook')) {
    return 'Facebook';
  } else {
    return referrer;
  }
};

// Function to track events with Mixpanel
export const trackEvent = (eventName, properties = {}, retries = 3, delay = 2000) => {
  const payload = JSON.stringify({ event: eventName, properties });
  const mixpanelURL = `https://api.mixpanel.com/track/?data=${btoa(payload)}`;

  let attempt = 0;

  const sendEvent = () => {
    mixpanel.track(eventName, properties, (response) => {
      if (!response && attempt < retries) {
        attempt++;
        console.warn(`Mixpanel event failed. Retrying ${attempt}/${retries}...`);
        setTimeout(sendEvent, delay);
      } else if (!response) {
        console.error("Mixpanel event failed after maximum retries.");
      } else {
        console.log("Mixpanel event sent successfully.");
      }
    });
  };

  // Attempt to send event
  sendEvent();

  // Ensure event is sent when the user closes the tab
  const sendOnUnload = () => {
    if (navigator.sendBeacon) {
      navigator.sendBeacon(mixpanelURL);
    } else {
      fetch(mixpanelURL, { method: "POST", keepalive: true });
    }
  };

  window.addEventListener("beforeunload", sendOnUnload);
};

// export const trackEvent = (eventName, properties = {}) => {
//   const referrer = document.referrer;
//   properties['Normalized Referrer'] = normalizeReferrer(referrer);
//   mixpanel.track(eventName, properties);
// };