import createAxios from "./httpCommon";
import Error from "./error";
import { useNavigate } from "react-router-dom";
import { trackEvent } from "../mixpanel";
import { useAppContext } from "../AppContext";
import { localStorageGetItem, localStorageSetItem } from '../App';


export default () => {
  const { error } = Error();
  const navigate = useNavigate();
  const {updateFormData}= useAppContext()

  const getUser = (setLoading, showToast, setDetails, setPersonalDetails = null) => {
    setLoading(true);
    const token = localStorageGetItem
("userToken");

    createAxios().then((axios) => {
      axios
        .get("/user", {
          headers: {
            "X-Auth-Token": token,
          },
        })
        .then(async (response) => {
   
         
          localStorageSetItem
("userData", JSON.stringify(response?.data?.data));
         
        setDetails({
          firstName: response?.data?.data.firstName,
          lastName: response?.data?.data.lastName,
          email: response?.data?.data?.email,
          isVerified:response?.data?.data?.isVerified,
          phoneNumber: response?.data?.data?.phoneNumber,
          address: {
            streetAddress: response?.data?.data?.address?.streetAddress ,
            LGA: response?.data?.data?.address?.city?.name,
            state: response?.data?.data?.address?.state?.name,
          },
          profilePicture:response?.data?.data?.profilePicture,
        });
if(setPersonalDetails){
        setPersonalDetails({
          firstName: response?.data?.data.firstName,
          lastName: response?.data?.data.lastName,
          email: response?.data?.data?.email,
          phoneNumber: response?.data?.data?.phoneNumber,
          address: {
            streetAddress: response?.data?.data?.address?.streetAddress ,
            LGA: response?.data?.data?.address?.city?.name,
            state: response?.data?.data?.address?.state?.name,
          },
          profilePicture:response?.data?.data?.profilePicture,
        });
      }
        
        setTimeout(() => {
             
          setLoading(false);
      }, 1000); 
          
        })

        .catch((e) => {
          // setLoading(false);

          const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
          error(e, setLoading, showToast, retriesDone);
        });
    });
  };


  const updateUser = (setLoading, updateDetails, showToast, setDetails) => {
    setLoading(true);
    const token = localStorageGetItem
("userToken");

    createAxios().then((axios) => {
      axios
        .patch("/user", updateDetails, {
          headers: {
            "X-Auth-Token": token,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(async (response) => {
        
        
          localStorageSetItem
("userData", JSON.stringify(response?.data?.data));
          setDetails({
            firstName: response?.data?.data.firstName,
            lastName: response?.data?.data.lastName,
            email: response?.data?.data?.email,
            phoneNumber: response?.data?.data?.phoneNumber,
            address: {
              streetAddress: response?.data?.data?.address?.streetAddress,
              LGA: response?.data?.data?.address?.city,
              state: response?.data?.data?.address?.state,
            },
            profilePicture:response?.data?.data?.profilePicture,
          });

          setTimeout(() => {
             
            setLoading(false);
        }, 1000); 
        
          const newFormData = {
            streetAddress: response?.data?.data?.address?.streetAddress,
            LGA: response?.data?.data?.address?.city,
            state: response?.data?.data?.address?.state,
          };

          // Update context
          updateFormData(newFormData);

          showToast({
            toastText: 'Profile Updated Successfully!',
            isSuccess: true
          });
          window.location.reload();

        })

        .catch((e) => {
        
          const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
          error(e, setLoading, showToast, retriesDone);
        });
    });
  };

  const updateUserSource = (setLoading, body, showToast,setShowSourceModal) => {
    setLoading(true);
    const token = localStorageGetItem
("userToken");

    createAxios().then((axios) => {
      axios
        .patch("/user", body, {
          headers: {
            "X-Auth-Token": token,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(async (response) => {
        console.log(response,'response')
        
//           localStorageSetItem
// ("userData", JSON.stringify(response?.data?.data));
// setLoading(false);
            setShowSourceModal(false);
showToast({
  toastText: 'Thank you!',
  isSuccess: true
});
         
        
         
          
        
        })

        .catch((e) => {
        
          const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
          error(e, setLoading, showToast, retriesDone);
        });
    });
  };


  const getReferral = (setLoading, showToast, setReferData) => {
    setLoading(true);
    const token = localStorageGetItem
("userToken");

    createAxios().then((axios) => {
      axios
        .get("/user/referral", {
          headers: {
            "X-Auth-Token": token,
          },
        })
        .then(async (response) => {

          
          setReferData(response?.data?.data)
        
        setTimeout(() => {
             
          setLoading(false);
      }, 1000); 
          
        })

        .catch((e) => {
          // setLoading(false);

          const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
          error(e, setLoading, showToast, retriesDone);
        });
    });
  };

  return {
    
   getUser, updateUser, getReferral, updateUserSource
    };
};