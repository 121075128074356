import React, { useState } from 'react'
import { Button, Form, Modal, Row,Col } from 'react-bootstrap'
import logo from '../../assets/virtue-logo-2.png'
import {Image} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { trackEvent } from '../../mixpanel'
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useAppContext } from '../../AppContext'
import successIcon from '../../assets/successful-icon.svg'


const VirtuePlusModal = ({ showModal, closeModal }) => {
  const {showToast}= useAppContext()
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // New state for success message
  const navigate = useNavigate();

 

  const handleSubmit = async(e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!email) {
      validationErrors.email = "Please enter your Email";
    }
    if (!phoneNumber) {
      validationErrors.phoneNumber = "Please enter your Phone Number";
    }

    setError(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const body = {
                    
        email: email,
        phoneNumber: phoneNumber,

      }
      console.log(body)
      try {
        
        const response = await axios.post('https://production-api.virtuesshop.co/user/join-virtue-plus',body);
      
        console.log(' details:', response);
       
      setTimeout(() => {
        setLoading(false);
        setIsSubmitted(true); 
      }, 1000);
        trackEvent('Virtue+ Member Registered', {
          email_address: email,
          phone_number: phoneNumber,
          attempted: new Date().toISOString(),
          device_type: navigator.userAgent
        });

      } catch (error) {
        console.error('There was an error!', error);
setLoading(false)
showToast({
  toastText: 'Failed!',
  isSuccess: false
});
        trackEvent('Failed Virtue+ Member Registration', {
          failure_reason: error.message || 'An unexpected error occurred',
          attempted_at: new Date().toISOString(),
        });
      }
      
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal} centered size="md">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div style={{ backgroundColor: "white", fontFamily:"  Outfit" }} className=""> {loading ?
           <div className='justify-content-center align-items-center text-center' style={{ padding: '80px' }}>
    <div className="spinner-border text-success" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
  : isSubmitted ? (
          <div className="login-form py-0 py-md-3 mb-3 px-0 px-md-2">

            <div className="text-center mb-3">
            <div>
            <img src={successIcon} style={{width:'50px', height:'50px'}} alt='success icon'/>
            </div>
              <h2 className="mt-3 mb-4">You are now a Virtue+ Member</h2>
            </div>

          

              <div className="text-center">
                <h4 style={{ fontWeight: "500", color:'#5D5D5D' }} className='px-0 px-md-4'>Your promo code is in your email inbox. 
                Please ensure you use it when checking out. </h4>
                <Button onClick={closeModal} className="btn-primary mt-5 w-100" style={{ fontSize: "20px" }}>
                  Check Mail
                </Button>
              </div>
            </div>
            ) : (
              <div className="login-form py-0 py-md-3 mb-md-5 px-0 px-md-4">

            <div className="text-center mb-3">
              <Image fluid src={logo} style={{ width: "90px" }} />
              <h2 className="mt-3 mb-3">Become a Virtue+ Member</h2>
              <h5 style={{ fontWeight: "500", textAlign: "left" , fontSize:"19px", color:'#5D5D5D'}} className="mb-1 mb-md-3">
                Get 10% off your first order and be the first to know when we launch exclusive discounts and free delivery offers.
                </h5>
            </div>
              <Form onSubmit={handleSubmit} className="w-100">
               
<Row><Col xs={12} md={6}><Form.Group className="mb-2">
                  {/* <Form.Label>Email address</Form.Label> */}
                  <Form.Control
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Please enter an email address"
                    value={email}
                    onChange={(e)=> setEmail(e.target.value)}
                    required
                  />
                  {error.email && <div className="text-danger" style={{ fontSize: "12px" }}>{error.email}</div>}
                </Form.Group>
</Col>
<Col xs={12} md={6}><Form.Group className='w-100'>
                  {/* <Form.Label>Phone Number</Form.Label> */}
                  <PhoneInput
      defaultCountry="NG"
      value={phoneNumber}
      onChange={setPhoneNumber}
      international
      countryCallingCodeEditable={false} // Prevent manual country code change
    />
{/*                  
                  <PhoneInput
        defaultCountry="ng" // Set default country (Nigeria)
        value={phoneNumber}
        onChange={(phoneNumber) => setPhoneNumber(phoneNumber)}
        placeholder='0810000000'
       className="custom-phone-container custom-phone-input"
      /> */}
                  {error.phoneNumber && <div className="text-danger" style={{ fontSize: "12px" }}>{error.phoneNumber}</div>}
                </Form.Group></Col></Row>
                

                

                <div className="mt-4 mb-3">
                  <Button type="submit" className="btn-primary w-100" disabled={loading}>
                    {loading ? "Joining..." : "Join our community"}
                  </Button>
                </div>

              </Form>
           
          </div>
           )}
        </div>
      </Modal.Body>
    </Modal>
  );
};


export default VirtuePlusModal
