import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import logo from '../../assets/virtue-logo-2.png'
import {Image} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../AppContext'
import userRoute from '../../services/userRoute'

const SourceModal = () => {
 const {showSourceModal, setShowSourceModal,showToast}= useAppContext()
const [formData, setFormData] = useState({
    leadSource: "",
  });
  const {updateUserSource}= userRoute()
   const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
     
       
          const handleChange = (e) => {
            const { name, value } = e.target;
            setFormData((prev) => ({ ...prev, [name]: value }));
          };
        
          
          const handleSubmit= (e)=> {
            e.preventDefault();
   
            const validationErrors = {};
            if (!formData.leadSource) {
              validationErrors.leadSource = 'Please select source';
            }
              setError(validationErrors);

              if (Object.keys(validationErrors).length === 0 ) {
                const body = {
                    
                    leadSource: formData.leadSource,
            
                  }
                updateUserSource(setLoading, body, showToast,setShowSourceModal)
                }

          }
  return (
    <Modal show={showSourceModal} centered size='md'>
        <Modal.Header></Modal.Header>
          <Modal.Body className=''>
          <div style={{backgroundColor:'white', }} className=''>
             
             <div className='login-form py-3 mb-5 px-4'>
           
            
                   <div className='text-center mb-3'><Image fluid src={logo} style={{maxWidth:'90px'}}/>
                  
                   <h4 style={{fontWeight:'500', textAlign:"left",}} className='text-center mt-3'>How did you hear about us?</h4>
                   </div>
                   {loading ?
           <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
    <div className="spinner-border text-success" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>:
                     <Form onSubmit={handleSubmit} className='w-100'>
                         <Form.Group className='mb-2'>
                                          
                                         
                                           
                                 
                             <Form.Select
                             className="custom-select"
                             onChange={handleChange}
                             value={formData.leadSource}
                             name="leadSource"
                             required 
                           >
                             <option value="" disabled>Select source</option>
                             <option value='Instagram'>Instagram</option> 
                       <option value='Facebook'>Facebook</option>
                         <option value='TikTok'>TikTok</option>
                         <option value='X (Twitter)'>X (Twitter)</option>
                         <option value='WhatsApp'>WhatsApp</option>
                         <option value='Friend'>Friend referral (use your friend's code and get 10% off your order)</option>
                         <option value='Other'>Other</option>
                        
                       </Form.Select>
                       {error.leadSource && <div className="text-danger" style={{fontSize:'12px'}}>{error.leadSource}</div>}
                                           </Form.Group>
                        
                         <div className='mt-4'><Button type='submit' className='btn-primary w-100' style={{fontSize:"20px"}}>Submit</Button></div>
                         
                     </Form>
}
                    
                 </div>
            </div>
          </Modal.Body>
          
            </Modal>
  )
}

export default SourceModal
